import React, { useRef } from "react";
import "./TextArea.css";

const TextArea = ({
  labelColor,
  label,
  required,
  value,
  placeholder,
  maxHeight,
  onKeyDown,
  onChange,
  onPaste,
  rows,
  textAreaContainerStyle,
}) => {
  const textareaRef = useRef(null);

  const handleTextareaChange = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  };

  return (
    <div className={`c_textarea__container`} style={textAreaContainerStyle}>
      <label
        className="c_textarea__label"
        style={{ color: labelColor ? labelColor : "white" }}
      >
        {label ? label : "Label"}{" "}
        {required ? (
          "*"
        ) : (
          <span
            style={{
              fontSize: "0.75rem",
              fontStyle: "italic",
              fontWeight: 300,
            }}
          >
            (optional)
          </span>
        )}
      </label>
      <textarea
        ref={textareaRef}
        className="c_textarea__input"
        value={value}
        placeholder={placeholder}
        rows={rows ? rows : 2}
        style={{
          resize: "none",
          overflowY: "auto",
          maxHeight: maxHeight ? maxHeight : "96px",
        }}
        onChange={(e) => {
          onChange(e);
          handleTextareaChange();
        }}
        onPaste={(e) => {
          onPaste(e);
          handleTextareaChange();
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default TextArea;
