import React from "react";
import "./Button.css";

const Button = ({ onClick, label, variant, disabled }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`custom__button ${variant ? variant : "contained"} ${
        disabled && "disabled-button"
      }`}
      onClick={() => onClick()}
    >
      {label ? label : "Label"}
    </button>
  );
};

export default Button;
