import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import Header from "./components/layout/header/Header";
import Home from "./pages/home/Home";
import EliteProgram from "./pages/program/elite/Elite";
import VideographersWanted from "./pages/eoi/form/VideographersWanted";
import GameRecordingRequest from "./pages/eoi/form/GameRecordingRequest";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/program/elite-10-week-rugby-program"
          element={<EliteProgram />}
        />

        <Route
          path="/form/videographer-interest"
          element={<VideographersWanted />}
        />

        <Route
          path="/form/game-recording-request"
          element={<GameRecordingRequest />}
        />
      </Routes>
    </Router>
  );
};

export default App;
