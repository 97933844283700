import React, { useState } from "react";
import TextField from "../../../components/input/textfield/TextField";
import "./GameRecordingRequest.css";
import { Button } from "../../../components/button/Button";
import { validateRequiredFields } from "../../../utils/Validation";
import Axios from "../../../api/axios/Axios";

const GameRecordingRequest = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    requestType: "",
    teams: "",
    address: "",
    date: "",
    ownership: "",
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!validateRequiredFields(data, [])) {
      return alert("Fields marked (*) are required.");
    } else return apiSubmitForm();
  };

  const apiSubmitForm = () => {
    setLoading(true);
    Axios.post("/api/v1/videorequest/eoi", data).then((res) => {
      const { success } = res.data;
      if (success) {
        setSuccess(true);
        return setLoading(false);
      }
    });
  };

  const handleNameInput = (e) => {
    return setData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleEmailInput = (e) => {
    return setData((prev) => ({ ...prev, email: e.target.value }));
  };

  const handleRequestTypeInput = (e) => {
    return setData((prev) => ({ ...prev, requestType: e.target.innerText }));
  };

  const handleOwnershipInput = (e) => {
    return setData((prev) => ({ ...prev, ownership: e.target.innerText }));
  };

  const handleTeam1Input = (e) => {
    return setData((prev) => ({ ...prev, teams: e.target.value }));
  };

  const handleAddressInput = (e) => {
    return setData((prev) => ({ ...prev, address: e.target.value }));
  };

  const handleDateInput = (e) => {
    return setData((prev) => ({ ...prev, date: e.target.value }));
  };

  const messageDialog = (
    <div className="eoi-v__wrapper eoi-v__wrapper--message-dialog">
      <h2 className="eoi-v__message-dialog eoi-v__message-dialog--title">
        Thank you for submitting your interest.
      </h2>

      <br />

      <p>
        Your game request will be added to our waiting list, subject to
        availability. We will be in touch with you as soon as possible to
        confirm our crew's availability for the game request.
      </p>
    </div>
  );

  return (
    <div className="eoi-v__container">
      {!success ? (
        <form className="eoi-v__form">
          <h1>Game Recording Request</h1>

          <p>
            Your game request will be added to our waiting list, subject to
            availability. Paid clients receive priority.
          </p>

          <br />

          <TextField
            label="Name"
            value={data.name}
            onChange={handleNameInput}
            placeholder="e.g. John Doe"
            required
          />

          <br />

          <TextField
            label="Email"
            type="email"
            value={data.email}
            onChange={handleEmailInput}
            placeholder="e.g. john.doe@email.com"
            required
          />

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              Who is making this request? *
            </span>

            <span className="form__wrapper form__wrapper--select-options">
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.requestType === "Player" && "active-button"
                }`}
                onClick={handleRequestTypeInput}
                required
              >
                Player
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.requestType === "Fan" && "active-button"
                }`}
                onClick={handleRequestTypeInput}
                required
              >
                Fan
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.requestType === "Coach" && "active-button"
                }`}
                onClick={handleRequestTypeInput}
                required
              >
                Coach
              </button>

              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.requestType === "Club" && "active-button"
                }`}
                onClick={handleRequestTypeInput}
                required
              >
                Club
              </button>
            </span>
          </div>

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              Recording Ownership *
            </span>

            <span className="form__wrapper form__wrapper--select-options">
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.ownership === "Client" && "active-button"
                }`}
                onClick={handleOwnershipInput}
                required
              >
                Client
              </button>

              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.ownership === "RugbyGo" && "active-button"
                }`}
                onClick={handleOwnershipInput}
                required
              >
                RugbyGo
              </button>
            </span>

            {data.ownership && (
              <>
                <br />
                <p
                  className="eoi-v__select active-button"
                  style={{ padding: 8 }}
                >
                  {data.ownership === "Client"
                    ? `The cost is $200 per game within the Metro area. You retain 100%
              ownership of the recording.`
                    : `RugbyGo films the game at no cost and retains 100% ownership of the recording.`}
                </p>
              </>
            )}
          </div>

          <br />

          <TextField
            label="What game would you like us to record?"
            value={data.teams}
            onChange={handleTeam1Input}
            placeholder="e.g. Unicorns vs Rhinos Under 14s"
            required
          />

          <br />

          <TextField
            label="Location/Address"
            value={data.address}
            onChange={handleAddressInput}
            placeholder="e.g. Mossfiel Dr, Hoppers Crossing VIC 3029"
            required
          />

          <br />

          <TextField
            label="Date"
            type="date"
            value={data.date}
            onChange={handleDateInput}
            required
          />

          <br />

          <Button
            onClick={() => handleSubmit()}
            label={!loading ? "Join Waiting List" : "Submitting.."}
            disabled={loading}
          />

          <br />
        </form>
      ) : (
        messageDialog
      )}
    </div>
  );
};

export default GameRecordingRequest;
