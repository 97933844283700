
export const validateRequiredFields = (obj, optionalKeys) => {
  /**This function will return true if all required fields have values,
    otherwise false */

  // if(ValidateObjectData(obj,["item2", "item2"]))

  // example of non required fields in an array:
  // const optionalKeys = ["about", "mobileNumber"];

  for (const key in obj) {
    if (!optionalKeys.includes(key) && obj[key] === "") return false;
  }

  return true;
};
