import React, { useState } from "react";
import TextField from "../../../components/input/textfield/TextField";
import TextArea from "../../../components/input/textarea/TextArea";
import "./VideographersWanted.css";
import { Button } from "../../../components/button/Button";
import { validateRequiredFields } from "../../../utils/Validation";
import Axios from "../../../api/axios/Axios";

const VideographersWanted = () => {
  const [data, setData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    sport: "",
    preferredLocation: "",
    preferredTeams: "",
    availability: "",
    travelFlexibility: "",
    needEquipment: "",
    needTraining: "",
    expertiseLevel: "",
    rateExpectations: "",
    workSamples: "",
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (
      !validateRequiredFields(data, [
        "mobile",
        "rateExpectations",
        "workSamples",
      ])
    ) {
      return alert("Fields marked (*) are required.");
    } else return apiSubmitForm();
  };

  const apiSubmitForm = () => {
    setLoading(true);
    Axios.post("/api/v1/videographers/eoi", data).then((res) => {
      const { success } = res.data;
      if (success) {
        setSuccess(true);
        return setLoading(false);
      }
    });
  };

  const handleFullNameInput = (e) => {
    return setData((prev) => ({ ...prev, fullName: e.target.value }));
  };
  const handleEmailInput = (e) => {
    return setData((prev) => ({ ...prev, email: e.target.value }));
  };

  const handleMobileInput = (e) => {
    return setData((prev) => ({ ...prev, mobile: e.target.value }));
  };

  const handleSportInput = (e) => {
    return setData((prev) => ({ ...prev, sport: e.target.innerText }));
  };

  const handlePreferredLocationInput = (e) => {
    return setData((prev) => ({ ...prev, preferredLocation: e.target.value }));
  };

  const handlePreferredTeamsInput = (e) => {
    return setData((prev) => ({ ...prev, preferredTeams: e.target.value }));
  };

  const handleAvailabilityInput = (e) => {
    return setData((prev) => ({ ...prev, availability: e.target.value }));
  };

  const handleTravelFlexibilityInput = (e) => {
    return setData((prev) => ({
      ...prev,
      travelFlexibility: e.target.innerText,
    }));
  };

  const handleNeedEquipmentInput = (e) => {
    return setData((prev) => ({ ...prev, needEquipment: e.target.innerText }));
  };

  const handleNeedTrainingInput = (e) => {
    return setData((prev) => ({
      ...prev,
      needTraining: e.target.innerText,
    }));
  };

  const handleExpertiseLevelInput = (e) => {
    return setData((prev) => ({ ...prev, expertiseLevel: e.target.innerText }));
  };

  const handleRateExpectationsInput = (e) => {
    return setData((prev) => ({ ...prev, rateExpectations: e.target.value }));
  };

  const handlePreviousWorkInput = (e) => {
    return setData((prev) => ({ ...prev, workSamples: e.target.value }));
  };

  const messageDialog = (
    <div className="eoi-v__wrapper eoi-v__wrapper--message-dialog">
      <h2 className="eoi-v__message-dialog eoi-v__message-dialog--title">
        Submission Successful!
      </h2>

      <br />

      <p>
        Thank you for submitting your interest. We will be in touch with you
        shortly. If you don’t receive a response within the next 24-48 hours,
        please check your spam folder.
      </p>
    </div>
  );

  return (
    <div className="eoi-v__container">
      {!success ? (
        <form className="eoi-v__form">
          <h1>Expression of Interest – Rugby Videographer</h1>

          <br />

          <p>
            If you’re interested in filming local rugby/league games for fun on
            weekends and getting paid, let us know! Training and equipment can
            be provided. The players and fans will appreciate it. No experience
            needed, just a great time!
          </p>

          <br />

          <TextField
            label="Full name"
            value={data.fullName}
            onChange={handleFullNameInput}
            placeholder="Full name"
            required
          />

          <br />

          <TextField
            label="Your email"
            value={data.email}
            onChange={handleEmailInput}
            placeholder="e.g. email@domain.com"
            required
          />

          <br />

          <TextField
            label="Phone number"
            value={data.mobile}
            onChange={handleMobileInput}
            placeholder="e.g. 0431 085 321"
          />

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              Sport(s) you record *
            </span>

            <span className="form__wrapper form__wrapper--select-options">
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.sport === "Union" && "active-button"
                }`}
                onClick={handleSportInput}
              >
                Union
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.sport === "League" && "active-button"
                }`}
                onClick={handleSportInput}
              >
                League
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.sport === "Both" && "active-button"
                }`}
                onClick={handleSportInput}
              >
                Both
              </button>
            </span>
          </div>

          <br />

          <TextArea
            label="Preferred location"
            placeholder="e.g. West Melbourne"
            value={data.preferredLocation}
            onChange={handlePreferredLocationInput}
            required
          />

          <br />

          <TextArea
            label="Preferred teams or Competitions to record"
            placeholder="e.g. Melbourne Under 14s"
            value={data.preferredTeams}
            onChange={(e) => handlePreferredTeamsInput(e)}
            required
          />

          <br />

          <TextField
            label="Availability"
            value={data.availability}
            onChange={handleAvailabilityInput}
            placeholder="e.g. weekends, evenings"
            required
          />

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              Location/Travel flexibility *
            </span>

            <span className={"form__wrapper form__wrapper--select-options"}>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.travelFlexibility === "Yes" && "active-button"
                }`}
                onClick={handleTravelFlexibilityInput}
              >
                Yes
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.travelFlexibility === "No" && "active-button"
                }`}
                onClick={handleTravelFlexibilityInput}
              >
                No
              </button>
            </span>
          </div>

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              Do you need assistance with equipment? *
            </span>

            <span className="form__wrapper form__wrapper--select-options">
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.needEquipment === "Yes" && "active-button"
                }`}
                onClick={handleNeedEquipmentInput}
              >
                Yes
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.needEquipment === "No" && "active-button"
                }`}
                onClick={handleNeedEquipmentInput}
              >
                No
              </button>
            </span>
          </div>

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              Do you need training to get started? *
            </span>

            <span className="form__wrapper form__wrapper--select-options">
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.needTraining === "Yes" && "active-button"
                }`}
                onClick={handleNeedTrainingInput}
              >
                Yes
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.needTraining === "No" && "active-button"
                }`}
                onClick={handleNeedTrainingInput}
              >
                No
              </button>
            </span>
          </div>

          <br />

          <div className="form__wrapper form__wrapper--col">
            <span className="eoi-v__select eoi-v__select--label">
              What’s your level of expertise? *
            </span>

            <span className="form__wrapper form__wrapper--select-options">
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.expertiseLevel === "Beginner" && "active-button"
                }`}
                onClick={handleExpertiseLevelInput}
              >
                Beginner
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.expertiseLevel === "Intermediate" && "active-button"
                }`}
                onClick={handleExpertiseLevelInput}
              >
                Intermediate
              </button>
              <button
                type="button"
                className={`eoi-v__select eoi-v__select--option ${
                  data.expertiseLevel === "Professional" && "active-button"
                }`}
                onClick={handleExpertiseLevelInput}
              >
                Professional
              </button>
            </span>
          </div>

          <br />

          <TextField
            label="What are your rate expectations?"
            value={data.rateExpectations}
            onChange={handleRateExpectationsInput}
            placeholder=""
          />

          <br />

          <TextArea
            label="Add portfolio or samples of previous work"
            placeholder="e.g. website or url link"
            value={data.workSamples}
            onChange={handlePreviousWorkInput}
          />

          <br />

          <Button
            onClick={() => handleSubmit()}
            label={!loading ? "Submit Your Interest" : " Submitting.."}
            disabled={loading}
          />

          <br />
        </form>
      ) : (
        messageDialog
      )}
    </div>
  );
};

export default VideographersWanted;
