import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import {
  MainContainer,
  OutterContainer,
  InnerContainer,
  InnerContent,
} from "../../components/layout/container/Container";
import { MdOutlinePlayCircleOutline } from "react-icons/md";
import { TbBrandYoutube } from "react-icons/tb";
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { LiaRunningSolid } from "react-icons/lia";
import { IoVideocam } from "react-icons/io5";

const Home = () => {
  const navigateTo = useNavigate();

  return (
    <MainContainer>
      <OutterContainer>
        <InnerContainer>
          <InnerContent className="home__inner-content">
            <span className="home__heading">Explore Our Content Online</span>

            <ul className="home-category-list">
              <li
                onClick={() => {
                  const url = "https://xreplay.app/rugbygo";
                  window.open(url, "_blank");
                }}
                className="home-category-list__item home-category-list__item--xreplay"
              >
                <div>
                  <span className="home-category-list__heading">XReplay</span>
                  <span className="home-category-list__type">
                    Online Streaming
                  </span>
                </div>

                <div className="home-category-list__image-wrapper">
                  <MdOutlinePlayCircleOutline className="home-category-list__icon" />
                </div>
              </li>

              <li
                onClick={() => {
                  const url = "https://www.youtube.com/@rugbygo.official";
                  window.open(url, "_blank");
                }}
                className="home-category-list__item home-category-list__item--youtube"
              >
                <div>
                  <span className="home-category-list__heading">YouTube</span>
                  <span className="home-category-list__type">
                    Online Streaming
                  </span>
                </div>

                <div className="home-category-list__image-wrapper">
                  <TbBrandYoutube className="home-category-list__icon" />
                </div>
              </li>

              <li
                onClick={() => {
                  const url = "https://www.instagram.com/rugbygo.official/";
                  window.open(url, "_blank");
                }}
                className="home-category-list__item home-category-list__item--instagram"
              >
                <div>
                  <span className="home-category-list__heading">Instagram</span>
                  <span className="home-category-list__type">Social Media</span>
                </div>

                <div className="home-category-list__image-wrapper">
                  <FaInstagram className="home-category-list__icon" />
                </div>
              </li>

              <li
                onClick={() => {
                  const url = "https://www.facebook.com/rugbygo.official";
                  window.open(url, "_blank");
                }}
                className="home-category-list__item home-category-list__item--facebook"
              >
                <div>
                  <span className="home-category-list__heading">Facebook</span>
                  <span className="home-category-list__type">Social Media</span>
                </div>

                <div className="home-category-list__image-wrapper">
                  <FiFacebook className="home-category-list__icon" />
                </div>
              </li>
            </ul>

            <br />
            <br />

            <section>
              <h2 className="home__heading">Forms</h2>

              <ul
                onClick={() => navigateTo("/form/game-recording-request")}
                className="home-category-list"
              >
                <li className="home-category-list__item home-category-list__item--elite-program">
                  <div>
                    <span className="home-category-list__heading">
                      Game Filming EOI
                    </span>
                    <span className="home-category-list__type">
                      Secure Your Team’s Spot on Our Waiting List!
                    </span>
                  </div>

                  <div className="home-category-list__image-wrapper">
                    <IoVideocam className="home-category-list__icon" />
                  </div>
                </li>
              </ul>
            </section>

            <br />
            <br />

            <span className="home__heading">Elite Program</span>

            <ul
              onClick={() => navigateTo("/program/elite-10-week-rugby-program")}
              className="home-category-list"
            >
              <li className="home-category-list__item home-category-list__item--elite-program">
                <div>
                  <span className="home-category-list__heading">Rugby</span>
                  <span className="home-category-list__type">Pre-season</span>
                </div>

                <div className="home-category-list__image-wrapper">
                  <LiaRunningSolid className="home-category-list__icon" />
                </div>
              </li>
            </ul>
          </InnerContent>
        </InnerContainer>
      </OutterContainer>
    </MainContainer>
  );
};

export default Home;
